<template>
<div class="col-md-10 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-row text-center" v-if="add">
            <h4>Seleccione su proveedor de correo electrónico</h4>            
          </div>
          <div class="form-row text-center" v-if="add">
            <div class="form-group col-md-4">
              <div id="office365" class="m-2 border border-info rounded" v-on:mouseover.prevent="changeClassMouseOver('office365')" v-on:mouseout.prevent="changeClassMouseOut('office365')">
                <a href="#" v-on:click.prevent="setConnection('office365')">
                  <div>
                      <img src="/images/office365.png" alt="Office 365">
                  </div>
                  <div>
                    <span>Office 365</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div id="gmail" class="m-2 border border-info rounded" v-on:mouseover.prevent="changeClassMouseOver('gmail')" v-on:mouseout.prevent="changeClassMouseOut('gmail')">
                <a href="#" v-on:click.prevent="setConnection('gmail')">
                  <div>
                      <img src="/images/gmail.png" alt="Google/Gmail">
                  </div>
                  <div>
                    <span>Google/Gmail</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div id="otros" class="m-2 border border-info rounded" v-on:mouseover.prevent="changeClassMouseOver('otros')" v-on:mouseout.prevent="changeClassMouseOut('otros')">
                <a href="#" v-on:click.prevent="setConnection('otros')">
                  <div>
                      <img src="/images/other_mail.png" alt="Otros">
                  </div>
                  <div>
                    <span>Otros</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Conexión servidor entrante(*):</label>
            <textarea rows="3" class="form-control" v-model="item.inb_connection" required />
          </div>
          <div class="form-group">
            <label>Conexión servidor saliente:</label>
            <textarea rows="3" class="form-control" v-model="item.inb_outgoing_connection" />
          </div>
          <div class="form-group">
            <label>Correo electrónico (*):</label>
            <input type="text" ref="inb_user" v-on:keyup="validarUsuario" pattern="^[a-z]+[a-z0-9_\-.@]+$" class="form-control" v-model="item.inb_user" required>
            <p v-if="inb_userMsg" class="alert alert-warning" role="alert">{{ inb_userMsg }}</p>
          </div>
          <div class="form-group">
            <label>Contraseña (*):</label>
            <input v-if="add" type="password" class="form-control" v-model="item.inb_password" required>
            <button v-if="!add" type="button" class="btn btn-secondary form-control" v-on:click.prevent="newPwd()" data-toggle="modal" data-target="#pwdModal">Cambiar contraseña</button>
            <pwdchange v-on:change-pwd="pwdChange(pwd)" v-bind:item="pwd"></pwdchange>              
          </div>
          <div class="form-group">
            <input type="checkbox" class="form-checkbox-input" id="inb_dsn" v-model="item.inb_dsn">
            <label for="inb_dsn">Notificación de estado de entrega:</label>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
          <br>
          <div class="form-group text-center">
            <label>Prueba de conexión (*):</label>
            <div class="form-row">
              <button type="button" class="btn btn-info" v-on:click.prevent="testConnection()">Iniciar prueba</button>
              <v-progress-circular
                v-if="testing_in || testing_out"
                :size="30"
                :width="3"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
            <div class="form-row">
              <textarea rows="2" class="form-control" v-model="test_in" />
            </div>
            <div class="form-row">
              <textarea rows="2" class="form-control" v-model="test_out" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import Select2 from '../select2/Select2.vue';
  import InboxPwdChange from './InboxPwdChange.vue';
  //import moment from 'moment';
  import {
    getConnectionMicrosoftImap,
    getConnectionGoogle,
    getConnectionLdap
  } from "../../plugins/passport-provider"

  export default {
    components: {
      Select2,
      'pwdchange': InboxPwdChange
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        ready: false,
        pwd: {},
        inb_userMsg: '',
        test_in: '',
        test_out: '',
        testing_in: false,
        testing_out: false
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.search = this.$route.params.search;
      
      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Conectar correo electrónico';
        this.buttontext = 'Agregar';
      } else {
        this.title = 'Editar correo electrónico';
        this.buttontext = 'Actualizar';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {   
      validarUsuario(e){
        var t = e.target;
        var pos = t.selectionStart;
        t.value = t.value.toLowerCase();
        t.setSelectionRange(pos, pos);

        var patron =/^[a-z]+[a-z0-9_\-.@]+$/; // Acepta números y letras _-.@
        var valido = patron.test(t.value);
        if (!valido){
          this.$refs.inb_user.style.backgroundColor = '#fff3cd';
          this.inb_userMsg = 'No se permiten caracteres especiales, use letras, números o los símbolos _-.@';
        } else {
          this.$refs.inb_user.style.backgroundColor = '';
          this.inb_userMsg = '';
        }
      },
      setClose(close){
        this.close = close;
      },
      saveItem(){
        this.item.inb_user = this.item.inb_user.toLowerCase();
        this.item.inb_owner_id = String(this.$store.state.user);

        if (this.add){
          let uri = '/inboxes/add';
          //alert(this.item);
          this.item.cmp_id = String(this.$store.state.company);
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplayInboxUser', params: { search: this.search } });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/inboxes/update';
          this.item.isOwner = true;
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then((response) => {
            if (this.close){
              this.$router.push({ name: 'DisplayInboxUser', params: { search: this.search } });
            }
            this.message = 'Registro guardado';
          })
          .catch((err) => {
            this.message = 'Error al guardar la información.';
          });
        }
      },
      getItem(id){
        let uri = '/inboxes/edit';
        var p = {};
        p.cmp_id = String(this.$store.state.company);
        p.id = id;
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          this.item = response.data;
        });
      },
      newPwd(){
        this.pwd = { inb_connection: this.item.inb_connection, inb_user: this.item.inb_user }
      },
      pwdChange(p){
        if (p.inb_password_new != p.inb_password_conf){
          alert('No se ha confirmado la nueva contraseña. Verifique los datos y vuelva a intentarlo.')
          return;
        }
        let uri = '/inboxes/securityadmin';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, { _id: this.item._id, inb_password_new: p.inb_password_new })
        .then((response) => {
          alert('La contraseña fue cambiada satisfactoriamente.');
          //this.$router.replace({ name: 'Profile' });
        }).catch((err) => {
          alert('No se ha podido cambiar la contraseña. Verifique los datos y vuelva a intentarlo.');
        });
      },
      cancel(){
        this.$router.replace({ name: 'DisplayInboxUser', params: { search: this.search } });
      },
      setConnection(p){
        switch (p) {
          case 'office365':
            this.$set(this.item, 'inb_connection', '{ "host":"outlook.office365.com", "port":993, "tls":true, "tlsOptions":{ "rejectUnauthorized":false } }');
            this.$set(this.item, 'inb_outgoing_connection', '{ "host":"smtp.office365.com", "port":587, "tls":{ "rejectUnauthorized":false } }');
            break;
          case 'gmail':
            this.$set(this.item, 'inb_connection', '{ "host":"imap.gmail.com", "port":993, "tls":true, "tlsOptions":{ "rejectUnauthorized":false } }');
            this.$set(this.item, 'inb_outgoing_connection', '{ "host":"smtp.gmail.com", "port":587, "tls":{ "rejectUnauthorized":false } }');
            break;        
          default:
            this.$set(this.item, 'inb_connection', '{ "host":"imap.dominio_xxxxxxxxxxxx.com", "port":993, "tls":true, "tlsOptions":{ "rejectUnauthorized":false } }');
            this.$set(this.item, 'inb_outgoing_connection', '{ "host":"smtp.dominio_xxxxxxxxxxxx.com", "port":587, "tls":{ "rejectUnauthorized":false } }');
            break;
        }
      },
      changeClassMouseOver(id){
        //debugger;
        document.getElementById(id).className = 'm-2 border border-info rounded shadow bg-white';
      },
      changeClassMouseOut(id){
        document.getElementById(id).className = 'm-2 border border-info rounded';
      },
      async testConnection(){
        let host = JSON.parse(this.item.inb_connection).host || '';
        let data = {};
        let base_url = process.env.NODE_ENV === "production" ? window.location.origin : "http://localhost:4000"

        switch (host) {
          case "outlook.office365.com":
            data = await getConnectionMicrosoftImap(base_url)
            break;
          /*
          case "imap.gmail.com":
            data = await getConnectionGoogle()
            break;
          case "ldap":
            data = await getConnectionLdap()
            break;
          */
        }
        
        this.test_in = 'Comprobando la conexión del servidor entrante ...';
        
        let uri = '/mailbox/api/testingoingconnection';     
        var p = {};
        p.cmp_id = String(this.$store.state.company);
        p.id = this.item._id;
        this.testing_in = true;
        this.axios.post(uri, p)
        .then((response) => {
          this.test_in = response.data.test;
          this.testing_in = false;
        })
        .catch((err) => {
          this.test_in = err.data.test;
          this.testing_in = false;
        });


        this.test_out = 'Comprobando la conexión del servidor saliente ...';
        let uri2 = '/mailbox/api/testoutgoingconnection';     
        this.testing_out = true;
        this.axios.post(uri2, p)
        .then((response) => {
          this.test_out = response.data.test;
          this.testing_out = false;
        })
        .catch((err) => {
          this.test_out = err.data.test;
          this.testing_out = false;
        });

      },

    } // END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }

    /*
    #inb_user {
      text-transform: lowercase;
    }
    */

  </style>