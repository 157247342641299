export const getConnectionMicrosoft = async () => {
  return new Promise((resolve, reject) => {
      let base_url = process.env.NODE_ENV === "production" ? "https://app.siged.co" : "http://localhost:4000"
      let url = `${base_url}/passaport/microsoft`

      popupCenter({ url, title: 'Connection microsoft', w: 550, h: 550 })

      window.addEventListener("message", function (event) {
          if (event.data?.accessToken) {
              let { data } = event;
              resolve(data)
          }
      });
  })
}

export const getConnectionMicrosoftImap = async (base_url) => {
  return new Promise((resolve, reject) => {
      let url = `${base_url}/imap/microsoft/signin`

      popupCenter({ url, title: 'Connection microsoft', w: 550, h: 550 })

      window.addEventListener("message", function (event) {
          if (event.data?.accessToken) {
              let { data } = event;
              resolve(data)
          }
      });
  })
}

export const getConnectionGoogle = async () => {
  return new Promise((resolve, reject) => {
      let base_url = process.env.NODE_ENV === "production" ? "https://app.siged.co" : "http://localhost:4000"
      let url = `${base_url}/passaport/google`

      popupCenter({ url, title: 'Connection google', w: 550, h: 550 })

      window.addEventListener("message", function (event) {
          if (event.data?.accessToken) {
              let { data } = event;
              resolve(data)
          }
      });
  })
}

export const getConnectionLdap = async () => {
  return new Promise((resolve) => {
      let url = `/ldap`

      popupCenter({ url, title: 'Connection ldap', w: 550, h: 550 })

      window.addEventListener("message", function (event) {
          console.log("message: ", event)
          if (event.data?.accessToken) {
              let { data } = event;
              resolve(data)
          }
      });
  })
}

const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(url, title,
      `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (window.focus) newWindow.focus();
}